<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Eventlog</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                                <label>Description</label>
                                <b-form-input v-model="search.description"></b-form-input>
                        </b-col>
                    </b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
                            <b-button variant="primary" squared @click="searchRequest">Search</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-table striped hover 
                                 :items="tableData.dataSource" 
                                 :fields="tableData.tableColumns" 
                                 :busy="tableData.isLoading" 
                                 :per-page="tableData.resultsPerPage" 
                                 id="eventsTable" 
                                 :current-page="tableData.currentPage" sort-icon-left>
                            <template #table-busy>
                                <div class="text-center my-2">
                                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                </div>
                            </template>
                            
                            <template #cell(name)="row">
                                <b-row align-v="center">
                                    <span class="status-good mr-2" v-if="row.item.published === true"></span>
                                    <span class="status-bad mr-2" v-if="row.item.published === false"></span>
                                    <span class="mr-auto">{{row.item.name}}</span>
                                </b-row>
                            </template>
                            
                            <template #cell(changeDate)="row">
                                <b-row align-v="center">
                                    <span class="mr-auto">{{row.item.changeDate | dateTimeFilter}}</span>
                                </b-row>
                            </template>

                            
                            <template #cell(actions)="row">
                                <b-row align-v="center" align-h="end">
                                    <b-button @click="openArea(row.item)" class="btn-icon" size="sm">
                                        <b-icon-chevron-right></b-icon-chevron-right>
                                    </b-button>
                                </b-row>
                            </template>

                        </b-table>
                    </b-row>
                    <b-row align-h="center" >
                            <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="rows"
                                :per-page="tableData.resultsPerPage"
                                aria-controls="eventsTable"
                            ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'Logger',
    data: () => ({
        search:{
            description: null
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'changeDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Message',
                    key: 'historyType',
                    sortable: true,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        this.tableData.isLoading = false
        this.setBreadcrumb([
        {
            text: 'Admin'
        },
        {
            text: 'Event Logger',
            active: true
        }
        ])
        this.searchRequest()
    },
    methods:{
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        ...mapActions(["searchEvent"]),
        clearFilter(){
            this.search = {
                description: null
            }
            this.searchRequest()
        },
        
        searchRequest() {
          this.tableData.isLoading = true
          let request = []
          
          if (this.search.description !== null) {
            request.push ({"key": "description", "value": this.search.description})
          }
          
          this.$store.commit('setSearchEventRequest', request)
          this.searchEvent()
          .then((response) => {
            this.tableData.dataSource = response.data
            this.tableData.isLoading = false
          })
        },
    },
    computed: {
      rows() {
        return this.tableData.dataSource.length
      },
    },
}
</script>
